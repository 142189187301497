import { addPropertyControls, ControlType, RenderTarget } from "framer"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"

function Pulse({ props, onAnimationComplete }) {
    const animateOut = props.direction === "out"

    return (
        <motion.div
            style={{
                position: "absolute",
                backgroundColor:
                    props.style === "border" ? "none" : props.color,
                border:
                    props.style === "border"
                        ? `${props.borderWidth}px solid ${props.color}`
                        : "none",
            }}
            variants={{
                in: {
                    opacity: props.pulseOpacity,
                    inset: "0px",
                    borderRadius:
                        props.radiusType == "full" ? 10000 : props.radiusValue,
                },
                out: {
                    opacity: 0,
                    inset: `-${props.pulseSize}px`,
                    borderRadius:
                        props.radiusType == "full"
                            ? 10000
                            : props.radiusValue + props.pulseSize,
                },
            }}
            initial={animateOut ? "in" : "out"}
            animate={animateOut ? "out" : "in"}
            transition={props.transition}
            onAnimationComplete={onAnimationComplete}
        ></motion.div>
    )
}

/*
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 * @framerIntrinsicWidth 20
 * @framerIntrinsicHeight 20
 */
export default function PulseEffect(props) {
    const [pulses, setPulses] = useState([])

    useEffect(() => {
        const createPulse = () => {
            const newPulseKey = Date.now() // Unique key for new pulse
            setPulses((prevPulses) => [...prevPulses, newPulseKey])
        }

        const interval = setInterval(createPulse, props.delay * 1000)
        createPulse()

        return () => clearInterval(interval) // Clear interval on component unmount
    }, [])

    const handleAnimationComplete = (completedPulseKey) => {
        setPulses((prevPulses) =>
            prevPulses.filter((key) => key !== completedPulseKey)
        )
    }

    return (
        <div>
            {pulses.map((key) => (
                <Pulse
                    key={key}
                    props={props}
                    onAnimationComplete={() => handleAnimationComplete(key)}
                />
            ))}
            <div
                style={{
                    position: "absolute",
                    inset: 0,
                    backgroundColor: props.color,
                    borderRadius:
                        props.radiusType == "full" ? 10000 : props.radiusValue,
                }}
            />
        </div>
    )
}

PulseEffect.displayName = "Pulse"

addPropertyControls(PulseEffect, {
    color: {
        type: ControlType.Color,
    },
    delay: {
        type: ControlType.Number,
        defaultValue: 1,
        min: 0.1,
        step: 0.01,
        unit: "s",
    },
    direction: {
        type: ControlType.Enum,
        defaultValue: "out",
        options: ["in", "out"],
        optionTitles: ["In", "Out"],
        displaySegmentedControl: true,
    },
    pulseSize: {
        type: ControlType.Number,
        defaultValue: 20,
        min: 1,
        step: 1,
    },
    pulseOpacity: {
        type: ControlType.Number,
        defaultValue: 0.5,
        min: 0,
        max: 1,
        step: 0.01,
    },
    style: {
        type: ControlType.Enum,
        defaultValue: "solid",
        options: ["solid", "border"],
        optionTitles: ["Solid", "Outline"],
        displaySegmentedControl: true,
    },
    borderWidth: {
        type: ControlType.Number,
        defaultValue: 5,
        min: 1,
        step: 1,
        title: "Outline Width",
        hidden: (props) => props.style !== "border",
    },
    radiusType: {
        type: ControlType.Enum,
        defaultValue: "full",
        options: ["full", "custom"],
        optionTitles: ["Full", "Custom"],
        displaySegmentedControl: true,
        title: "Radius",
    },
    radiusValue: {
        type: ControlType.Number,
        defaultValue: 20,
        min: 1,
        step: 1,
        title: " ",
        hidden: (props) => props.radiusType !== "custom",
    },
    transition: {
        type: ControlType.Transition,
        defaultValue: {
            type: "spring",
            stiffness: 150,
            damping: 60,
        },
    },
})
